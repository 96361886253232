import React from "react";
import styled from "styled-components";
import { color, space, typography, shadow } from "styled-system";
import { device } from "../../utils";
const Paragraph = styled.p`
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 28px;
  ${color};
  ${space};
  ${typography};
  ${shadow};


  @media ${device.lg} {
    font-size: 17px;
   
    margin-bottom: 5px;
  }
`;

const ParagraphSmall = styled(Paragraph)`
  font-size: 17px;
  letter-spacing: -0.5px;
  line-height: 22px;
  ${color};
  ${space};
  ${typography};
  ${shadow};
`;

const Text = ({ variant, ...props }) => {
  let TextRender;

  switch (variant) {
    case "small":
      TextRender = ParagraphSmall;
      break;
    default:
      TextRender = Paragraph;
  }

  return <TextRender color="text" {...props} />;
};

export default Text;
