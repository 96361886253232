import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components";
import './popup-form.scss'
import { countries } from "../../utils/countries"
import Select from 'react-select';

const Bookbutton = styled.button`
    font-size: 16px;
    height: 48px;
    width: 197px;
    padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
    position: relative;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;
const PopupForm = ({isModalOpen, setIsModalOpen}) => {
  const emailRegex = /^([^@\s]+)@((?:[^@\s]+\.)+[^@\s]+)$/;
  const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

  const modalRef = useRef(null);

  const [formErrors, setFormErrors] = useState({
    fullName: 'default',
    email: 'default',
    requestedTreatment: 'default',
    country: 'default',
    phone: 'default'
  })
  const [selectedCountry, setSelectedCountry] = useState('United Kingdom')
  const [countryCode, setCountryCode] = useState('+44' + ' ')
  const [phone, setPhone] = useState('');
  const [loaderActive, setLoaderActive] = useState(false)
  let fullPhone = countryCode + phone;


  const options = countries.map(country => ({
    value: { name: country.name, dial_code: country.dial_code, code: country.code },
    label: country.name + ' ' + country.dial_code,
  }));

  const validateIsEmpty = (value, field, fieldName) => {
    if (value === '') {
      setFormErrors(prevState => ({
        ...prevState,
        [field]: `${fieldName} is required!`
      }))
    } else {
      setFormErrors(prevState => ({
        ...prevState,
        [field]: ''
      }))
    }
  }

  const validateEmail = (email) => {

    if (email === '') {
      setFormErrors((prevState) => ({
        ...prevState,
        email: 'E-mail is required',
      }));
    } else if (!emailRegex.test(email)) {
      setFormErrors((prevState) => ({
        ...prevState,
        email: 'Incorrect e-mail',
      }));
    } else {
      setFormErrors((prevState) => ({
        ...prevState,
        email: '',
      }));
    }
  };

  const handleCountryChange = (selectedOption) => {
    const selectedItem = selectedOption.value.name + ' ' + selectedOption.value.dial_code;
    setSelectedCountry(selectedItem);

    setCountryCode(selectedOption.value.dial_code+" ");
    setFormErrors(prevState => ({
      ...prevState,
      country: '',
    }));
  }

  const validatePhone = (phone) => {
    if (!phoneRegex.test('+' + phone)) {
      setFormErrors(prevState => ({
        ...prevState,
        phone: 'Incorrect number'
      }))
    } if (phone === '') {
      setFormErrors(prevState => ({
        ...prevState,
        phone: 'Phone is required'
      }))
    } if (phoneRegex.test('+' + phone)) {
      setFormErrors(prevState => ({
        ...prevState,
        phone: ''
      }))
    }
  }

  const validateCountry = (country) => {
    if (country === '') {
      setFormErrors(prevState => ({
        ...prevState,
        country: 'Country is required!',
        phone: 'Select country code before phone!'
      }));
    } else {
      setFormErrors(prevState => ({
        ...prevState,
        country: '',
      }));
    }
  };

  const handlePhoneChange = (event) => {
    let justNumbersArray = event.split(" ");
    if (justNumbersArray.length > 1) {
      justNumbersArray = justNumbersArray.slice(1);
    } else {
      justNumbersArray.push('');
      justNumbersArray = justNumbersArray.slice(1);
    }
    const justNumbers = justNumbersArray.join("").replace(/[^0-9+]/g, '');
    setPhone(justNumbers);
    validatePhone(event.replace(/[^0-9]/g, ''))
    validateCountry(selectedCountry)
  }

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setIsModalOpen(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    validateIsEmpty(event.target[0].value, 'fullName', 'Full name')
    validateEmail(event.target[1].value)
    validateCountry(selectedCountry)
    validatePhone(event.target[3].value.replace(/[^0-9]/g, ''))
    validateIsEmpty(event.target[4].value, 'requestedTreatment', 'Requested Treatment')

    if(isFormValid) {
      setLoaderActive(true)
      setTimeout(() => {
        window.location.href = "https://connect.pabau.com/bookings.php?compid=8607";
      }, 2000);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const noErr = Object.keys(formErrors)
      .every(value => formErrors[value] === '');
    setIsFormValid(noErr)
  }, [formErrors]);

  const [isFormValid, setIsFormValid] = useState(false)
  return (
    <div className={`modal-wrapper ${isModalOpen && 'modal-wrapper_opened'}`}>
      <form className='modal-wrapper__modal' ref={modalRef} onSubmit={handleSubmit}>
        <span className='modal-wrapper__modal__close' onClick={() => setIsModalOpen(false)}>X</span>
        <div className='modal-wrapper__modal__heading'>
          <h2>Book A Treatment</h2>
        </div>
        <div className="modal-wrapper__modal__fields">
          <div className="modal-wrapper__modal__fields__input-wrapper">
            <input type="text"
                   placeholder="Full Name..."
                   onBlur={(event) => validateIsEmpty(event.target.value, "fullName", "Full Name")}
                   onChange={(event) => validateIsEmpty(event.target.value, "fullName", "Full Name")}
            />
            {formErrors.fullName !== "" && formErrors.fullName !== "default" &&
              <span
                className="modal-wrapper__modal__fields__input-wrapper__err-message">{formErrors.fullName}</span>
            }
          </div>
          <div className="modal-wrapper__modal__fields__input-wrapper">
            <input type="email"
                   placeholder="Email Address..."
                   onBlur={(event) => validateEmail(event.target.value)}
                   onChange={(event) => validateEmail(event.target.value)}
            />
            {formErrors.email !== "" && formErrors.email !== 'default' &&
              <span
                className="modal-wrapper__modal__fields__input-wrapper__err-message">{formErrors.email}</span>
            }
          </div>
          <div className="modal-wrapper__modal__fields__input-wrapper">
            <Select
              value={selectedCountry}
              onChange={handleCountryChange}
              options={options}
              placeholder={selectedCountry ? selectedCountry : "Country code"}
              className="modal-wrapper__modal__fields__input-wrapper__country-input-wrapper"
              classNames={{
                control: () =>
                  "modal-wrapper__modal__fields__input-wrapper__country-input-wrapper__country-input",
              }}
            />
            {formErrors.country !== "" && formErrors.country !== "default" &&
              <span
                className="modal-wrapper__modal__fields__input-wrapper__err-message">{formErrors.country}</span>
            }
          </div>
          <div className="modal-wrapper__modal__fields__input-wrapper">
            <input type="text"
                   placeholder="Phone Number"
                   value={fullPhone}
                   onChange={(event) => handlePhoneChange(event.target.value)}
                   onBlur={(event) => handlePhoneChange(event.target.value)}
            />
            {formErrors.phone !== "" && formErrors.phone !== "default" &&
              <span
                className='modal-wrapper__modal__fields__input-wrapper__err-message'>{formErrors.phone}</span>
            }
          </div>
          <div className="modal-wrapper__modal__fields__input-wrapper">
              <input type="text"
                     placeholder="Requested Treatment..."
                     onBlur={(event) => validateIsEmpty(event.target.value, "requestedTreatment", "Requested Treatment")}
                     onChange={(event) => validateIsEmpty(event.target.value, "requestedTreatment", "Requested Treatment")}
              />
              {formErrors.requestedTreatment !== "" && formErrors.requestedTreatment !== "default" &&
                <span
                  className="modal-wrapper__modal__fields__input-wrapper__err-message">{formErrors.requestedTreatment}</span>
              }
            </div>
        </div>

        <textarea name="" id="" rows="4" placeholder='Enquiry...'>

        </textarea>

        <div
           data-aos-duration="500"
           data-aos-once="false"
           data-aos-delay="500"
           activeClassName="active">
          <Bookbutton type='submit'>
            {loaderActive ?
              <span className='modal-wrapper__modal__loader'></span>
              :
              "Book appointment"
            }
            </Bookbutton>
        </div>
      </form>
    </div>
  );
};

export default PopupForm;
