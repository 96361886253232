import React from "react";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import "./src/scss/style.scss";

import { GlobalProvider } from "./src/context/GlobalContext";
import Layout from "./src/components/Layout";

const isProduction = process.env.NODE_ENV === "production";

export const onClientEntry = () => {
  if (!isProduction) {
    // Development mode: Bypass Cookiebot and load scripts directly
    console.log('Bypassing Cookiebot in development mode (localhost)');
    
    // Function to load tracking scripts immediately in development
    const loadScripts = () => {
      // Load Google Tag Manager (GTM)
      const gtmScript = document.createElement('script');
      gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${process.env.GTM_TRACKING}`;
      gtmScript.async = true;
      document.head.appendChild(gtmScript);

      // Load Facebook Pixel
      const fbPixelScript = document.createElement('script');
      fbPixelScript.src = `https://connect.facebook.net/en_US/fbevents.js`;
      fbPixelScript.async = true;
      document.head.appendChild(fbPixelScript);

      // Load Google Analytics
      const gaScript = document.createElement('script');
      gaScript.src = `https://www.google-analytics.com/analytics.js`;
      gaScript.async = true;
      document.head.appendChild(gaScript);

      // Load Social9 script
      const social9Script = document.createElement('script');
      social9Script.src = "//cdn.social9.com/js/socialshare.min.js";
      social9Script.async = true;
      document.head.appendChild(social9Script);
    };

    // Call loadScripts immediately in development
    loadScripts();

  } else {
    // Production mode: Use Cookiebot for consent management
    console.log('Checking for Cookiebot in production mode');

    const checkCookiebot = setInterval(() => {
      if (window.Cookiebot) {
        clearInterval(checkCookiebot);
        console.log('Cookiebot is available');

        window.addEventListener("CookieConsentDeclaration", function () {
          const consent = window.Cookiebot.consents;

          // If marketing consent is given, load GTM, Facebook Pixel, and Social9 scripts
          if (consent.marketing) {
            console.log('Marketing consent given, loading GTM, Facebook Pixel, and Social9');

            // Load Google Tag Manager (GTM)
            const gtmScript = document.createElement('script');
            gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${process.env.GTM_TRACKING}`;
            gtmScript.async = true;
            document.head.appendChild(gtmScript);

            // Load Facebook Pixel
            const fbPixelScript = document.createElement('script');
            fbPixelScript.src = `https://connect.facebook.net/en_US/fbevents.js`;
            fbPixelScript.async = true;
            document.head.appendChild(fbPixelScript);

            // Load Social9 script
            const social9Script = document.createElement('script');
            social9Script.src = "//cdn.social9.com/js/socialshare.min.js";
            social9Script.async = true;
            document.head.appendChild(social9Script);
          } else {
            console.log('Marketing consent not given, marketing scripts are blocked');
          }

          // If statistics consent is given, load Google Analytics
          if (consent.statistics) {
            console.log('Statistics consent given, enabling Google Analytics');
            const gaScript = document.createElement('script');
            gaScript.src = `https://www.google-analytics.com/analytics.js`;
            gaScript.async = true;
            document.head.appendChild(gaScript);
          } else {
            console.log('Statistics consent not given, Google Analytics is blocked');
          }
        });
      }
    }, 100);
  }
};

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

export const wrapRootElement = ({ element }) => (
  <GlobalProvider>{element}</GlobalProvider>
);
